.chart-settings-container {
  /* background-color: #f9f9f9; */
  padding: 20px;
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  margin: 20px auto;
  max-width: 900px;
  text-align: center;
}

.axis-selectors {
  display: flex;
  gap: 20px; /* Adds spacing between the X and Y axis selectors */
  align-items: center; /* Aligns the label and select field in the center vertically */
  margin-bottom: 20px; /* Adds space below the select fields */
}

.axis-label {
  display: flex;
  flex-direction: column; /* Stack the label above the select dropdown */
  font-size: 14px;
}

.axis-dropdown {
  margin-top: 5px; /* Adds a small space between the label and the select dropdown */
  padding: 8px; /* Adds padding inside the select field */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px; /* Adds rounded corners */
  width: 200px; /* Sets a fixed width for the select field */
}

.chart-type-dropdown {
  margin-top: 5px; /* Adds a small space between the label and the select dropdown */
  padding: 8px; /* Adds padding inside the select field */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px; /* Adds rounded corners */
  width: 200px; /* Sets a fixed width for the select field */
}

.chart-type-selector {
  margin-bottom: 20px;
}

.chart-type-label {
  font-weight: bold;
  color: #333;
}

.chart-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 900px;
  height: 400px;
  transition: transform 0.2s ease-in-out;
}

.chart-container:hover {
  transform: scale(1.02);
}
