
.table-container {
  max-height: 800px;
  overflow-x: auto; /* Allow horizontal scrolling */
  margin: 20px 0; /* Adjust margin as needed */
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  color: #333;
  min-width: 600px; /* Minimum width to avoid cutting off */
}

.table th,
.table td {
  padding: 5px 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #007bff; /* Primary color for header */
  color: white;
  position: sticky; /* Sticky positioning for fixed header */
  top: 0; /* Stick the header at the top */
  z-index: 1; /* Ensure the header stays on top of the content */
  cursor: pointer; /* Indicate sortable columns */
}


.table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.table td {
  background-color: white; /* Background for table cells */
}

.search-input {
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #007bff; /* Change the color when focused */
}

/* Add styles for the table's responsive behavior */
@media (max-width: 768px) {
  .table {
    font-size: 0.9em; /* Slightly smaller text on smaller screens */
  }

  .table th,
  .table td {
    padding: 10px 12px; /* Reduced padding */
  }
}
