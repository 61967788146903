/* ChatMessage.css */

.chat-message {
    margin: 0.5rem 0;
    padding: 0.75rem;
    border-radius: 10px;
    max-width: 70%;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  .user-message {
    background-color: #d1e7ff;
    align-self: flex-start;
    display: flex;
    align-items: center; /* Center-align items vertically */
    justify-content: space-between; /* Space between text and icon */
    padding: 8px;
    width: 100%;
}

  .message-text {
    margin-right: 10px; /* Optional: Add margin for spacing between text and icon */
  }
  
  .link-icon {
    cursor: pointer; /* Keep cursor as pointer */
  }

.bot-message {
  background-color: #e1e1e1;
  align-self: flex-start;
}
  
  /* Basic styling for tabs container */
  .tabs {
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 2px solid #4B5563; /* Bottom border for the tabs */
  }
  
  /* Tab button styling */
  .tab {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    margin-right: 10px;
    cursor: pointer;
    border: none; /* Remove default border */
    border-radius: 5px 5px 0 0;
    background-color: #f1f1f1; /* Light background for inactive tabs */
    color: #4B5563; /* Text color */
    font-weight: 600; /* Bold text */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
  }

.pre-chat-message {
  background-color: #e3f2fd; /* Positive background color */
  color: #1976d2; /* Text color */
  border-left: 4px solid #1976d2; /* Optional border for emphasis */
  display: flex; /* Enable flexbox */
  justify-content: space-between; /* Space between text and button */
  align-items: center; /* Center items vertically */
  padding: 8px; /* Add some padding for better spacing */
}

.try-it-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none; /* No borders */
  border-radius: 5px; /* Rounded corners */
  padding: 8px 12px; /* Some padding */
  cursor: pointer; /* Pointer cursor on hover */
  margin-left: auto; /* Push the button to the right */
}

@keyframes fadeIn {
    from {
        opacity: 0; /* Start fully transparent */
    }
    to {
        opacity: 1; /* Fully visible */
    }
}
  
  /* Active tab styling */
  .tab.active {
    background-color: #007BFF; /* Primary color for the active tab */
    color: #ffffff; /* White text color for the active tab */
    border-bottom: none; /* Remove bottom border */
    font-weight: bold; /* Make the active tab text bold */
  }
  
  /* Hover effect for tabs */
  .tab:hover {
    background-color: #e2e8f0; /* Light gray on hover */
    color: #2d3748; /* Darker text color on hover */
  }
  
  /* Tab content styling */
  .tab-content {
    border: 1px solid #ccc; /* Border around tab content */
    padding: 20px; /* Space inside the tab content area */
    background-color: #fff; /* White background for content */
    border-radius: 0 0 5px 5px; /* Rounded corners at the bottom */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
    width: auto;
    overflow: hidden; /* Prevent overflow */
  }
  
  /* Additional styles for suggestion items */
  .suggestion-item {
    padding: 5px 10px; /* Adjust padding as needed */
    border-bottom: 1px solid #ddd; /* Add a subtle border */
    list-style: none; /* Remove default bullet points */
    cursor: pointer; /* Optional: Set a pointer cursor for interaction */
  }
  
  .right-panel-open .chat-message-container {
    margin-right: 300px; /* Adjust this value based on the width of your right panel */
  }
  

  .tab-icon {
    width: 20px;   /* Adjust width of the SVG icon */
    height: 20px;  /* Adjust height of the SVG icon */
    margin-right: 5px;  /* Space between icon and text */
  }
  
  .tab.active .tab-icon {
    color: #0d6efd;  /* Change color when tab is active */
  }

  .chat-message-container {
    display: flex; /* Use flex to layout child components */
    flex-direction: column; /* Stack items vertically */
    padding-right: 20px; /* Add padding to avoid overlap */
    transition: margin-right 0.3s; /* Smooth transition when panel opens */

  }

  .complete-message {
    background-color: #e6ffe6;
    border-left: 4px solid #4caf50;
    padding: 10px;
    margin: 10px 0;
  }
  
  .message-icon {
    width: 24px;  /* Adjust width as needed */
    height: 24px; /* Adjust height as needed */
    vertical-align: middle;
    margin-right: 8px; /* Add some spacing between the icon and text */
  }

  .icon-text-container {
    display: flex;
    align-items: center; /* Vertically center the icon and text */
  }

  .copy-feedback {
    font-size: 0.85rem;
    margin-left: 10px;
    animation: fadeIn 0.1s ease;
}